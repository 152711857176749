<template>
    <event-module-component/>
</template>

<script>
    import EventModuleComponent from "@/components/admin/EventModuleComponent";

    export default {
        name: "EventModule",
        title: "Eventos y Espectáculos | Private",
        components: { EventModuleComponent }
    }
</script>

<style scoped>

</style>